<template>
  <div>
    <div
      v-if="$route.query.aprovar"
      class="titulo-pagina mt-2"
    >
      <span style="font-size: 17px"> Dados informados pelo Contratante </span>
    </div>
    <v-form
      ref="form"
      class="row mt-2"
    >
      <input-date
        v-model="form.dataSolicitacao"
        :label="$t('modulos.envio_material.formulario.data_solicitacao')"
        :placeholder="$t('geral.inputs.selecione')"
        class="col-12 col-md-3"
        obrigatorio
        disabled
      />
      <input-select
        v-model="form.tipoEnvioMaterial"
        :options="tipoEnvioMaterialFiltrado"
        :label="$t('modulos.envio_material.formulario.logistica')"
        :placeholder="$t('geral.inputs.selecione')"
        class="col-12 col-md-3"
        :disabled="ehVisualizar || !!$route.query.aprovar || podeEditar"
        obrigatorio
      />
      <input-ordem-servico
        v-model="form.ordemServicoId"
        class="col-12 col-md-3 text-center"
        :disabled="
          form.tipoEnvioMaterial != 'LaboratorioCliente' || ehVisualizar || podeEditar
        "
        :label="$t('modulos.envio_material.formulario.ordem_servico')"
        :obrigatorio="form.tipoEnvioMaterial == 'LaboratorioCliente'"
      />
      <input-cliente
        v-model="form.contratanteId"
        class="col-12 col-md-3"
        :label="$t('modulos.envio_material.formulario.contratante')"
        obrigatorio
        :disabled="
          ehVisualizar || !participanteLogadoConta || !!$route.query.aprovar || podeEditar
        "
      />

      <input-select
        v-if="buscarPermissao('Participante', 'Inserir')"
        v-model="form.contatoContratanteId"
        class="col-12 col-md-3"
        :options="opcoes.contato"
        :label="$t('modulos.envio_material.formulario.contato_contratante')"
        :placeholder="$t('geral.inputs.selecione')"
        :loading="loading.contatoLoading"
        :input-adicionar="true"
        :disabled="
          !form.contratanteId?.value || ehVisualizar || !!$route.query.aprovar
        "
        obrigatorio
        com-botao-adicionar
      >
        <template #botaoAdicionar>
          <botao-padrao
            :botao-adicionar-input="true"
            :disabled="!form.contratanteId?.value || ehVisualizar"
            @click="abreModalAdicionaClienteContato"
          >
            <span style="font-size: 23px"> + </span>
          </botao-padrao>
        </template>
      </input-select>

      <input-cliente
        v-if="
          !ehLaboratorio &&
            !$route.query.aprovar &&
            buscarPermissao('Participante', 'Inserir')
        "
        v-model="form.solicitanteId"
        class="col-12 col-md-3"
        :label="$t('modulos.envio_material.formulario.solicitante')"
        obrigatorio
        :disabled="ehVisualizar || !!$route.query.aprovar || podeEditar"
        estilo-botao-adicionar
        com-botao-adicionar
      >
        <template #botaoAdicionar>
          <botao-padrao
            :disabled="ehVisualizar"
            :botao-adicionar-input="true"
            :tooltip="
              $route.query.aprovar
                ? $t('modulos.envio_material.aprovacao_criar_solicitante')
                : ''
            "
            @click="abreModalAdicionaCliente"
          >
            <span style="font-size: 23px"> + </span>
          </botao-padrao>
        </template>
      </input-cliente>

      <input-cliente
        v-if="ehLaboratorio || $route.query.aprovar"
        v-model="form.solicitanteContratanteId"
        class="col-12 col-md-3"
        :label="$t('modulos.envio_material.formulario.solicitante')"
        obrigatorio
        :disabled="ehVisualizar || !!form.id || !!$route.query.aprovar"
        estilo-botao-adicionar
        com-botao-adicionar
      >
        <template #botaoAdicionar>
          <botao-padrao
            :disabled="ehVisualizar"
            :botao-adicionar-input="true"
            @click="abreModalAdicionaCliente"
          >
            <span style="font-size: 23px"> + </span>
          </botao-padrao>
        </template>
      </input-cliente>
      <input-select
        v-model="form.tipoEnvioId"
        :options="tipoEnvioFiltrado"
        :label="$t('modulos.envio_material.formulario.tipo_envio')"
        :placeholder="$t('geral.inputs.selecione')"
        class="col-12 col-md-3"
        :disabled="ehVisualizar || !!$route.query.aprovar || podeEditar"
        obrigatorio
        @change="() => trocaDeTipoEnvio()"
      />
      <input-date
        v-model="form.dataAgendamento"
        :label="$t('modulos.envio_material.formulario.data_agendamento')"
        :placeholder="$t('geral.inputs.selecione')"
        class="col-12 col-md-3"
        :allowed-days="!tipoEnvioVisivelRoteirizacao ? null : diasEnvio"
        apenas-datas-futuras
        :obrigatorio="form.tipoEnvioMaterial != 'RecebimentoSemAgendamento'"
        :disabled="!diasEnvio || ehVisualizar || !!$route.query.aprovar"
      />
      <input-text
        v-model="form.nomeTransportadora"
        :label="$t('modulos.envio_material.formulario.nome_transportadora')"
        class="col-12 col-md-3"
        :disabled="
          ehVisualizar || tipoEnvioVisivelRoteirizacao || !!$route.query.aprovar
        "
      />
      <input-text
        v-model="form.notaFiscal"
        :label="$t('modulos.envio_material.formulario.nota_fiscal')"
        class="col-12 col-md-3"
        numero-minimo="0"
        :max="50"
        type="number"
        :disabled="ehVisualizar || !!$route.query.aprovar"
      />
      <input-text
        v-model="form.quantidadeVolumes"
        :label="$t('modulos.envio_material.formulario.qtd_volumes')"
        class="col-12 col-md-3"
        type="number"
        numero-minimo="1"
        :disabled="ehVisualizar || !!$route.query.aprovar"
      />
      <input-text
        v-model="form.pesoEstimado"
        :label="$t('modulos.envio_material.formulario.peso_estimado')"
        class="col-12 col-md-3"
        type="number"
        numero-minimo="1"
        :max="20"
        :disabled="ehVisualizar || !!$route.query.aprovar"
      />

      <input-text
        v-model="form.observacao"
        :label="$t('modulos.envio_material.formulario.observacao')"
        class="col-12 col-md-12"
        :disabled="ehVisualizar || !!$route.query.aprovar"
      />
    </v-form>
    <contato-particiapante
      ref="contato-modal"
      :participante-id="form.contratanteIdId?.value"
      @atualizar-contato="atualizarContatoCriado"
    />
    <criacao-cliente-modal
      ref="modal-criacao-cliente"
      :cliente-id="clienteId"
      :disabled="ehVisualizar"
      :id-participante-clonar="
        $route.query.aprovar ? form.solicitanteContratanteId?.value : null
      "
      @atualizarSolicitante="atualizarSolicitante"
    />
    <div v-if="$route.query.aprovar">
      <div class="titulo-pagina mt-4">
        <div
          style="font-size: 17px"
          class="mb-4"
        >
          Dados para Aprovação
        </div>
      </div>
      <input-cliente
        v-model="form.solicitanteId"
        :label="$t('modulos.envio_material.formulario.solicitante')"
        obrigatorio
        estilo-botao-adicionar
        com-botao-adicionar
      >
        <template #botaoAdicionar>
          <botao-padrao
            :disabled="ehVisualizar"
            :botao-adicionar-input="true"
            :tooltip="
              $route.query.aprovar
                ? $t('modulos.envio_material.aprovacao_criar_solicitante')
                : ''
            "
            @click="abreModalAdicionaCliente"
          >
            <span style="font-size: 23px"> + </span>
          </botao-padrao>
        </template>
      </input-cliente>
    </div>
    <visualizar-historico
      :eh-visualizar="ehVisualizar"
      :historico="form.historico"
      :situacao="form.situacao"
      :contato-atendimento="form.contatoAtendimento?.text"
    />
  </div>
</template>

<script>
import { DropdownModel } from '@/common/models/geral/DropdownModel';
import { DropdownTipoEnvio } from '@/common/models/geral/DropdownTipoEnvio';
import { InputCliente, InputOrdemServico } from '@components/inputs';
import TipoEnvioService from '@/common/services/cadastros/TipoEnvioService';
import EnumeradorService from '@/common/services/cadastros/EnumeradorService';
import ParticipanteService from '@/common/services/cadastros/ParticipanteService';
import UsuarioService from '@/common/services/cadastros/UsuarioService';
import OrdemServicoService from '@/common/services/cadastros/OrdemServicoService.js';
import CriacaoClienteModal from './modais/CriacaoClienteModal.vue';
import { sortBy, cloneDeep } from 'lodash';
import VisualizarHistorico from './VisualizarHistorico.vue';
import moment from 'moment';
import ContatoParticiapante from './modais/ContatoParticiapante.vue';
import { mapGetters } from 'vuex';
import helpers from '@/common/utils/helpers';
export default {
  components: {
    InputCliente,
    CriacaoClienteModal,
    InputOrdemServico,
    VisualizarHistorico,
    ContatoParticiapante,
  },
  emits: ['validarFormulario'],
  props: {
    form: { type: Object, default: null },
    id: { type: String, default: null },
    ehVisualizar: { type: Boolean, default: false },
  },

  data() {
    return {
      filtrarRoteiroDaListaDeEnvio: false,
      dadosRequisitosClienteFormatado: null,
      tiposEnvioVisivelRoteirizacao: [],
      clienteId: null,
      loading: {
        tipoEnvioLoading: true,
        contatoLoading: false,
      },
      opcoes: {
        tipoEnvio: [],
        tipoEnvioMaterial: [],
        contato: [],
      },
      diasEnvio: [],
      primeiraVez: true,
      ehLaboratorio: false,
      apelidoParticipanteResponsavel: null,
    };
  },
  computed: {
    ...mapGetters('Autenticacao', [
      'participanteLogadoConta',
      'participanteSelecionado',
      'usuario',
    ]),
    tipoEnvioMaterialFiltrado() {
      if(!this.participanteLogadoConta && this.form.tipoEnvioMaterial == 'LaboratorioCliente' && this.form.id) {
        return this.opcoes.tipoEnvioMaterial.filter(
          (tipoEnvioMaterial) => tipoEnvioMaterial.value == 'LaboratorioCliente'
        );
      }
      if (!this.participanteLogadoConta)
        return this.opcoes.tipoEnvioMaterial.filter(
          (tipoEnvioMaterial) => tipoEnvioMaterial.value == 'ClienteLaboratorio'
        );

      if (this.form.situacao == 'EmExecucao')
        return this.opcoes.tipoEnvioMaterial;

      return this.opcoes.tipoEnvioMaterial.filter(
        (tipoEnvioMaterial) =>
          tipoEnvioMaterial.value != 'LaboratorioSemAgendamento'
      );
    },
    tipoEnvioFiltrado() {
      if (this.filtrarRoteiroDaListaDeEnvio) {
        return this.opcoes.tipoEnvio.filter(
          (tipoEnvio) => tipoEnvio.flagVisivelRoteirizacao == false
        );
      }
      return this.opcoes.tipoEnvio;
    },
    tipoEnvioVisivelRoteirizacao() {
      return this.tiposEnvioVisivelRoteirizacao.some(
        (el) => el.id == this.form.tipoEnvioId
      );
    },
    podeEditar(){
      return !this.participanteLogadoConta && this.form.tipoEnvioMaterial == 'LaboratorioCliente' && !!this.form.id
    }
  },
  async mounted() {
    const promisesOpcoes = [];
    promisesOpcoes.push(this.buscarTiposEnvio());
    promisesOpcoes.push(this.buscarEnumTipoEnvioMaterial());
    await Promise.all(promisesOpcoes);
    if (!this.participanteLogadoConta) await this.buscarParticipante(this.participanteSelecionado);
    if (!this.participanteLogadoConta) await this.buscarContato(this.participanteSelecionado);
    if (!this.participanteLogadoConta && !this.id) this.form.tipoEnvioMaterial = 'ClienteLaboratorio';
  },
  methods: {
    atualizarDadosContratante: async function (id) {
      if (id) {
        await this.buscarContato(id);
        this.buscarDiasEnvio(id);
        this.buscarParticipante(id);
      }
    },
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    trocaDeTipoEnvio() {
      this.form.dataAgendamento = null;
      this.$nextTick().then(() => {
        if (this.tipoEnvioVisivelRoteirizacao && !this.form.id)
          this.form.nomeTransportadora = this.apelidoParticipanteResponsavel;
        this.desabilitarNomeTransportadora = true;
      });
    },
    atualizarSolicitante(participante) {
      if (!participante) return;
      this.form.solicitanteId = new DropdownModel(participante, 'apelido');
    },
    async atualizaContato(id) {
      if (id) await this.buscarContato(id);
    },
    abreModalAdicionaCliente() {
      this.clienteId = null;
      this.$refs['modal-criacao-cliente'].abrirModal();
    },
    abreModalAdicionaClienteContato() {
      this.$refs['contato-modal'].abrirModal();
    },
    async buscarTiposEnvio() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      await TipoEnvioService.listarComFlagEnvioMaterial()
        .then((res) => {
          this.tiposEnvioVisivelRoteirizacao = cloneDeep(res.data.items).filter(
            (el) => el.flagVisivelRoteirizacao
          );
          this.opcoes.tipoEnvio = new DropdownTipoEnvio(res.data.items);
          this.loading.tipoEnvioLoading = false;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    filtros() {
      return {
        situacoes: ['Calibrado', 'NaoCalibrado', 'Reprovado'],
        situacoesAuxiliares: ['AguardandoEntrega'],
        participanteId: this.form.contratanteId?.value ?? '',
        participanteSolicitanteId: this.form.solicitanteId?.value ?? '',
      };
    },
    buscarEnumTipoEnvioMaterial() {
      return EnumeradorService.buscar('EnumTipoEnvioMaterial').then((res) => {
        this.opcoes.tipoEnvioMaterial = res;
      });
    },
    buscarParticipante: async function (id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      await ParticipanteService.buscar(id)
        .then((res) => {
          this.$emit('clienteWatcherToggle');
          this.buscarParticipanteResponsavel(
            res.data.participanteResponsavelId
          );
          this.form.contratanteId = new DropdownModel(res.data, 'apelido');
          if (!this.id) this.atualizarSolicitante(res.data);

          if (res.data.flagLaboratorio && !this.participanteLogadoConta)
            this.ehLaboratorio = true;
        })
        .catch(() => {
          this.toastErro(
            this.$t('modulos.ocorrencia_padrao.erros.id_invalido...')
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarParticipanteResponsavel(id) {
      if (!id) return;
      ParticipanteService.buscar(id).then((res) => {
        this.apelidoParticipanteResponsavel = res.data.apelido;
      });
    },
    buscarContatoUsuarioSolicitante: async function (id) {
      UsuarioService.buscar(id).then((res) => {
        if (res.data.contato)
          this.form.contatoContratanteId = new DropdownModel(res.data.contato);
      });
    },
    async buscarContato(id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      await ParticipanteService.buscaParticipantesContatosPorId(id)
        .then((res) => {
          this.opcoes.contato = new DropdownModel(res.data);
          if (!this.participanteLogadoConta) {
            this.buscarContatoUsuarioSolicitante(this.usuario.id);
          }
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    atualizarContatoCriado(contatoCriadoNome) {
      ParticipanteService.buscaParticipantesContatosPorId(
        this.form.solicitanteId?.value
      )
        .then((res) => {
          this.opcoes.contato = new DropdownModel(res.data);
        })
        .then(() => {
          this.form.contatoContratanteId = this.opcoes.contato.find(
            (contato) => contato.text == contatoCriadoNome
          );
        });
    },
    buscaDadosOrdemServico(id) {
      if (!id) return;
      this.$store.dispatch('Layout/iniciarCarregamento');
      OrdemServicoService.buscar(id)
        .then((res) => {
          // this.$emit('clienteWatcherToggle');

          this.atualizarSolicitante(res.data?.clienteParticipante);
          this.form.instrumentos = res.data?.ordensServicosInstrumentos;
          this.form.instrumentos?.forEach((item) => {
            item.participante = res.data?.clienteParticipante;
            item.flagSelecionado = true;
            item.id = item.instrumentoId;
          });
        })
        .catch(() => {
          this.toastErro(
            this.$t('modulos.ocorrencia_padrao.erros.id_invalido...')
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.$emit('clienteWatcherToggle');
        });
    },
    async buscarDiasEnvio(id) {
      this.diasEnvio = [];
      this.filtrarRoteiroDaListaDeEnvio = false;
      this.$store.dispatch('Layout/iniciarCarregamento');
      ParticipanteService.buscar(id)
        .then((res) => {
          if (res.data?.flagEnvioMaterialSegundaFeira == true)
            this.diasEnvio.push(0);
          if (res.data?.flagEnvioMaterialTercaFeira == true)
            this.diasEnvio.push(1);
          if (res.data?.flagEnvioMaterialQuartaFeira == true)
            this.diasEnvio.push(2);
          if (res.data?.flagEnvioMaterialQuintaFeira == true)
            this.diasEnvio.push(3);
          if (res.data?.flagEnvioMaterialSextaFeira == true)
            this.diasEnvio.push(4);

          if (this.diasEnvio.length == 0) {
            this.toastAlerta(
              this.$t('modulos.envio_material.erros.cliente_sem_dias_envio')
            );
            this.filtrarRoteiroDaListaDeEnvio = true;
            this.form.tipoEnvioId = null;

            this.diasEnvio = [0, 1, 2, 3, 4];
            this.form.dataAgendamento = null;
            this.$refs.form.resetValidation();
            return;
          }
          if (!this.$route.params?.id) this.primeiraVez = false;
          if (!this.primeiraVez) this.buscarDataMaisProxima(this.diasEnvio);
          else this.primeiraVez = false;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },

    buscarDataMaisProxima(dias) {
      const hoje = moment();
      let datasCaluladas = [];

      dias.forEach((dia) => {
        const proximoDataDisponivel = hoje.clone().day(dia + 1);
        if (proximoDataDisponivel.isBefore(hoje))
          proximoDataDisponivel.add(1, 'week');

        datasCaluladas.push(proximoDataDisponivel);
      });
      let data = sortBy(datasCaluladas, (data) => data.diff(hoje))[0];

      this.form.dataAgendamento = data.format('YYYY-MM-DD');
    },
  },
};
</script>
